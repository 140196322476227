.newsletters {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: "list";
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: .5em;
  overflow: auto;

  .newsletter {
    // width: 100%;
    // height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    padding: 1rem;
    background-color: rgba(244, 243, 228, 0.8);

    &:nth-child(odd) {
      background-color: #D9D9D6;
      color: #13294B;
    }

    &:hover {
      border-radius: 0.4rem;
      border-bottom: 1px solid #0057b7;
      color: #132948;
      cursor: pointer;
    }
    .description {
      font-size: 1.5rem;
      font-family: inherit;
    }
  }
}
