.overflow {
  .screen {
    position: absolute;
    width: 100vw;
    height: 100vh;
    .rightPanel {
      display: grid;
      height: 100%;
      width: 100%;
      grid-template-areas:
        "topMargin rightMargin"
        "hamburger90 rightMargin"
        "afterIconMargin rightMargin"
        "buttons rightMargin";
      grid-template-columns: 1fr 0rem;
      grid-template-rows: 1rem 2rem 4em 1fr;
      overflow: auto;
      z-index: 200;
      .topMargin {
        grid-area: topMargin;
      }
      .hamburger90 {
        grid-area: hamburger90;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1rem;

        .icon {
          justify-self: end;
          width: 2rem;
          display: grid;
          cursor: context-menu;
        }

        .rightMargin {
          width: 1rem;
        }
      }
      .afterIconMargin {
        grid-area: afterIconMargin;
        height: 100%;
      }
      .buttons {
        grid-area: buttons;
        display: grid;
        height: 100%;
        width: 100%;
        grid-template-columns: min-content;
        grid-auto-rows: min-content;
        justify-content: end;
        row-gap: 1rem;

        .option {
          height: 100%;
          display: grid;
          grid-template-areas: "button optionRightMargin";
          grid-template-columns: min-content 1rem;
          font-family: inherit;
          font-weight: inherit;
          justify-self: end;
          .button {
            grid-area: button;
            cursor: pointer;
            font-family: inherit;
            font-weight: inherit;
            font-size: 2rem;
            background-color: rgba(255, 255, 255, 0.1);
            color: black;
            justify-self: end;
            border: none;
            white-space: nowrap;
          }
          .optionRightMargin {
            width: 100%;
            grid-area: optionRightMargin;
          }
        }
      }
      .rightMargin {
        width: 100%;
        height: 100%;
        grid-area: rightMargin;
      }
    }
  }
}
