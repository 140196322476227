.calendar {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas:
      "photo"
      "list";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    row-gap: 1em;
    overflow: hidden;
    overflow-x: hidden;
  
    .photo {
      grid-area: photo;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    // .disciplines {
    //   grid-area: disciplines;
    //   width: 100%;
    //   height: 100%;
    // }
  
    .list {
      grid-area: list;
      width: 100%;
      height: 100%;
      overflow: auto;
      display: grid;
      grid-auto-rows: min-content;
      grid-template-columns: 1fr;
      row-gap: 2rem;
  
      .event {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-areas: "leftMargin . rightMargin"
                              "leftMargin description rightMargin"
                              "leftMargin date rightMargin"
                              "leftMargin . rightMargin";
          grid-template-columns: 1rem 1fr 1rem;
          grid-template-rows: 1rem min-content min-content 1rem;
          grid-auto-rows: max-content;
          // padding: 1rem;
          background-color: rgba(244, 243, 228, 0.8);
  
          &:hover {
              border-radius: 0.4rem;
              border-bottom: 1px solid #0057B7;
              color: #132948;
              cursor: pointer;
          }
        .description {
          width: 100%;
          display: grid;
          
          grid-area: description;
          font-size: 1.5rem;
          font-family: inherit;
          overflow-wrap: break-word;
        }
        .date {
          grid-area: date;
        }
      }
    }
  }
  