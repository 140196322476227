.disciplines {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
  "artistic diving water masters open swimming high"
    "artisticLabel divingLabel waterLabel  mastersLabel  openLabel swimmingLabel highLabel"
    "artisticLabel2 . waterLabel2 . openLabel2 . highLabel2";
    
  grid-template-columns: repeat(7, min-content);
  grid-template-rows: 1.5rem min-content min-content;
  place-content: center;
  column-gap: .5rem;

  .artisticLabel {
    grid-area: artisticLabel;
    place-self: center;
    font-size: .5rem;
  }
  .artisticLabel2 {
    grid-area: artisticLabel2;
    place-self: center;
    font-size: .5rem;
  }
  .waterLabel {
    grid-area: waterLabel;
    place-self: center;
    font-size: .5rem;
  }
  .waterLabel2 {
    grid-area: waterLabel2;
    place-self: center;
    font-size: .5rem;
  }
  .divingLabel {
    grid-area: divingLabel;
    place-self: center;
    font-size: .5rem;
  }
  .mastersLabel {
    grid-area: mastersLabel;
    place-self: center;
    font-size: .5rem;
  }
  .swimmingLabel {
    grid-area: swimmingLabel;
    place-self: center;
    font-size: .5rem;
  }
  .openLabel {
    grid-area: openLabel;
    place-self: center;
    font-size: .5rem;
  }
  .openLabel2 {
    grid-area: openLabel2;
    place-self: center;
    font-size: .5rem;
  }
  .highLabel {
    grid-area: highLabel;
    place-self: center;
    font-size: .5rem;
  }
  .highLabel2 {
    grid-area: highLabel2;
    place-self: center;
    font-size: .5rem;
  }

  .artistic {
    grid-area: artistic;
    height: 100%;
    place-self: center;
  }
  .water {
    grid-area: water;
    height: 100%;
    place-self: center;
  }
  .diving {
    grid-area: diving;
    height: 100%;
    place-self: center;
  }
  .masters {
    grid-area: masters;
    height: 100%;
    place-self: center;
  }
  .swimming {
    grid-area: swimming;
    height: 100%;
    place-self: center;
  }
  .open {
    grid-area: open;
    height: 100%;
    place-self: center;
  }
  .high {
    grid-area: high;
    height: 100%;
    place-self: center;
  }
}
