.accordion {
  display: grid;
  width: 100%;
  grid-template-areas:
    "headerWrapper"
    "children";
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;

  .headerWrapper{
      grid-area: headerWrapper;
      width: 100%;
      grid-template-areas: "leftMargin toggler header rightMargin";
      grid-template-columns: .125rem max-content max-content 1fr;
      grid-template-rows: 1fr;
      border: 2px solid lightblue;
      border-radius: .3rem;
      display: grid;
      place-items: center;
      column-gap: 1rem;
      overflow: hidden;

    .leftMargin {
        grid-area: leftMargin;
    }
    .toggler {
      grid-area: toggler;
    }
    .header {
      grid-area: header;
    }
    .rightMargin {
        grid-area: rightMargin;
    }
  }

  .children {
    grid-area: children;
  }
}
