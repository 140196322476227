.topMenu {
  width: 100%;
  height: calc(100%);
  overflow: hidden;
  display: grid;
  grid-template-areas: "leftMargin logo menuBar language hamburger rightMargin";
  grid-template-rows: 1fr;
  grid-template-columns: 0rem 6rem 1fr min-content 2rem 0rem;
  column-gap: 1rem;
  background-color: #F3F3E7;
  color: #13294B;

  @media (orientation: landscape) {
    grid-template-columns: 0rem 8rem 1fr min-content 0rem 0rem;
  }

  .logo {
    grid-area: logo;
    height: 100%;
    width: 100%;
    display: grid;
    align-self: center;
    //align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-self: start;
    cursor: pointer;

    .icon {
      display: grid;
      justify-self: start;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 100%;
      width: 100%;
    }
  }
  .menuBar {
    display: grid;
    width: 100%;
    height: 100%;
    grid-area: menuBar;
    justify-self: center;
    align-self: center;
    overflow: hidden;
    justify-content: center;
    background-color: #F3F3E7;

    @media (orientation: portrait) {
      display: none;
    }
  }
  .hamburger {
    justify-self: flex-end;
    grid-area: hamburger;
    width: 2rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    align-items: center;
    justify-content: end;
    cursor: context-menu;
    .hamburgerIcon {
      display: grid;
      height: 100%;
      width: 2rem;
      justify-self: end;
    }

    @media (orientation: landscape) {
      display: none;
    }
  }
  .language {
    height: 100%;
    grid-area: language;
    display: grid;
    grid-template-areas: "social socialsep eng sep esp";
    grid-template-columns: min-content 0rem min-content min-content min-content;
    grid-template-rows: 1fr;
    place-content: center;
    column-gap: 0.5rem;
    .social {
      height: 100%;
      width:100%;
      grid-area: social;
    }
    .esp,
    .eng,
    .sep,
    .socialsep {
      height: 100%;
      width: 100%;
      display: grid;
      place-content: center;
      cursor: pointer;
    }
    .socialsep {
      grid-area: socialsep;
    }
    .eng {
      height: 100%;
      grid-area: eng;
      place-self: center;

      &.selected {
        color: #00A3E0;
      }
    }
    .sep {
      grid-area: sep;
    }
    .esp {
      height: 100%;
      grid-area: esp;

      &.selected {
        color: #00A3E0;
      }
    }
  }

  .overflow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    overflow: auto;
    z-index: 10;
    overflow: hidden;
  }
}
