.home {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas: "content" "partners";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 14rem;
    overflow: auto;

    .content {
        width:100%;
        height: 100%;
        ///overflow: scroll;
        grid-area: content;
        display: grid;
        grid-template-rows: 2fr 3rem;
        grid-template-columns: 1fr;
        grid-template-areas: "welcome"
                             "disciplines" ;
                            
        row-gap: 1rem;
        .welcome {
            height: 100%;
            width: 100%;
            display: grid;
            grid-area: welcome;
            overflow: hidden;
        }
        .disciplines {
            height: 100%;
            width: 100%;
            grid-area: disciplines;
            display: grid;
            //overflow: hidden;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            
        }
       

        
    }
    .partners {
        height: 100%;
        width: 100%;
        grid-area: partners;
        display: grid;
        // overflow: hidden;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        place-self: center;
        
    }
    
}