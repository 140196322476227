.userPage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "photo"
    "topMargin"
    "content";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1rem 5fr;
  overflow: hidden;

  .topMargin {
    grid-area: topMargin;
    height: 100%;
    width: 100%;
  }
  .photo {
    width: 100%;
    height: 100%;
    grid-area: photo;
    overflow: hidden;
  }
  .content {
    grid-area: content;
    overflow: auto;
    display: grid;
    width: 100%;
    height: 100%;
    grid-auto-rows: 1fr;

    .section {
      width: 100%;
      height: 100%;
      display: grid;
      row-gap: 1rem;
      grid-auto-rows: max-content;

      .sectionRow {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        overflow: hidden;
        row-gap: .5rem;

        .sectionColumn {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          overflow: hidden;

          .columnContent {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            overflow: hidden;
            

            
          }
        }
      }
    }
  }
}
