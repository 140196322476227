.carousel {
    display: grid;
    // width: 100%;
    // height: 100%;
    grid-template-columns: 100vw;
    grid-template-rows: max-content;
    overflow: hidden;
    background: var(--background);

    .frame {
        display: grid;
        // width: 5rem;
        // height: 100%;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        grid-auto-rows: max-content;
        //overflow: auto;
        animation: scroll 130s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: running;
        
        &.noanimation {
          animation-play-state: paused;
        }

        .image {
          display: grid;
          place-self: center;
          height: inherit;

          .img {
            height: 30vh;
            
          }
        }
    }

}
@keyframes scroll {
    from {
      transform: translate(-4000px)
    }
    to {
      transform: translate(0)
    }
  }