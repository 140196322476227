.sitePage {
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: mimmax(auto, 20vh);
  grid-auto-flow: row;
  overflow: auto;

  
  .row {
    width: 100%;
    height: var(--height,100%);
    display: grid;
    grid-template-areas: "marginTop"
                          "rowBody"
                          "marginBottom";
    grid-template-columns: 1fr;
    grid-template-rows: var(--marginTop) 1fr var(--marginBottom);
    background-color: var(--backgroundColor);
    color: var(--color);
    overflow: hidden;
  }
  .rowMarginTop {
    grid-area: marginTop;
    height: 100%;
    width: 100%;
  }
  .rowBody {
    grid-area: rowBody;
     width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-rows:  1fr;
    overflow: hidden;
    background-color: var(--backgroundColor);
    color: var(--color);
  }
  .rowMarginBottom{
    grid-area: marginBottom;
    height: 100%;
    width: 100%;
  }
 
  .column {
    display: grid;
    width: var(--width, '100%');
    height: var(--height, '100%');
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    // .row {
    //   width: 100%;
    //   height: 100%;
    // }
  }
  .columnContent {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    place-content: stretch;
  }
  .image {
    width: var(--width, unset);
    height: var(--height, unset);
    place-self: center;
  }
}
