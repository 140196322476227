.photoHeader {
    display: grid;
    // border-radius: .5rem;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-areas: "tm tm tm"
                      ". photo. "
                      "bm bm bm";
    grid-template-columns:0rem 1fr 0rem;
    grid-template-rows: 0rem 1fr 0rem;

    &.link {
      cursor: pointer;
    }
    .photoFrame {
      width: 100%;
      height: 100%;
      grid-area: photo;
      place-self: center;    
       overflow: hidden;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      .image {
        //  width: 100%;
        //  height: 100%;
        fill: black;
        place-self: center;
      }

    }
    .title {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      border-radius: 1rem;
      place-self: center;

      display: grid;
      grid-template-areas: "tm tm tm"
                          ". titleText ."
                            "bm bm bm";
      grid-template-rows:1rem 1fr 1rem;
      grid-template-columns: .5rem 1fr .5rem;
      top: 0;
      left: 0;
      
      font-weight: 900;
      color: white;
      text-align: center;
      align-items: center;
      background-color: transparent;
      opacity: 1;

      .titleText {
        grid-area: titleText;
        font-size: calc(2rem + 1vw);
        padding: .25rem;
        border-radius: 1rem;
        place-self: center;
      }
    }
  }
