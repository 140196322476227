.eventsList {
  display: grid;
  width: 100%;
  height: 100%;
  min-width: 0;
  grid-template-areas:
    "upcomingEventLeftMargin upcomingEventTopMargin upcomingEventRightMargin"
    "upcomingEventLeftMargin upcoming upcomingEventRightMargin"
    "upcomingEventLeftMargin upcomingEventBottomMargin upcomingEventRightMargin"
    "upcomingEventLeftMargin calendar upcomingEventRightMargin";
  // "past";
  grid-template-rows: 0rem min-content 0rem min-content;
  grid-template-columns: 0 1fr 0;
  // row-gap: 1rem;
  overflow: hidden;

  .upcomingEventTopMargin {
    grid-area: upcomingEventTopMargin;
    width: 100%;
    height: 100%;
  }
  .upcomingEventBottomMargin {
    grid-area: upcomingEventBottomMargin;
    width: 100%;
    height: 100%;
  }
  .upcomingEventLeftMargin {
    grid-area: upcomingEventLeftMargin;
    width: 100%;
    height: 100%;
  }
  .upcomingEventRightMargin {
    grid-area: upcomingEventRightMargin;
    width: 100%;
    height: 100%;
  }
  .upcoming,
  .past {
    grid-area: upcoming;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
    row-gap: 0rem;

    .event {
      width: 100%;
      // height: 100%;
      min-height: 300px;
      display: grid;
      grid-template-areas:
        "right"
        ".";

      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0rem;
      background-color: #0057B7;
      color: #F3F3E7;
      cursor: pointer;

      background: var(--background);
      background-size: contain;
      
      .left {
        overflow: hidden;
        width: 100%;
        height: 100%;
        grid-area: left;
        display: grid;
        grid-template-areas: ". photo";
        grid-template-rows: 1fr;
        grid-template-columns: 0rem 1fr;
        .photo {
          grid-area: photo;
          width: unset;
          height: 100%;
          overflow: hidden;
          border-radius: 1em;
        }
      }
      .right {
        overflow: scroll;
        grid-area: right;
        width: 100%;
        height: 100%;
        display: grid;
        row-gap: 1rem;
        grid-template-areas:
          ". . ."
          ". upcomingEvent ."
          ". description ."
          ". date ."
          ". navigate ."
          ". countDown ."
          ". . .";
        grid-template-rows: 0rem min-content min-content min-content 1fr min-content .5rem;
        grid-template-columns: 1rem 1fr 1rem;
        // background: var(--background);
        color: var(--color);

        .upcomingEvent {
          height: 100%;
          width: 100%;
          grid-area: upcomingEvent;
          display: grid;
          font-size: 1.5rem;
          font-family: kallisto;
          justify-content: start;
          direction: rtl;
        }
        .description {
          width: 100%;
          height: 100%;
          grid-area: description;
          display: grid;
          font-size: 1rem;
          font-family: inherit;
          justify-content: start;
          direction: rtl;
          overflow: hidden;
        }
        .date {
          width: 100%;
          height: 100%;
          grid-area: date;
          display: grid;
          // place-self: flex-start;
          font-size: .9rem;
          justify-content: start;
          direction: rtl;
        }
        .navigate {
          height: 100%;
          width: 100%;
          grid-area: navigate;
          display: grid;
          align-self: end;
          align-items: end;
          grid-auto-flow: column;
          justify-content: end;
          grid-auto-columns: max-content min-content;
          font-size: .9rem;
          column-gap: 1rem;
          .description {
            align-self: end;
          }
          .icon {
            font-size: 1rem;
          }
        }
        .countDown {
          width: 100%;
          height: 100%;
          display: grid;
          grid-area: countDown;
          justify-self: end;
          align-self: center;

          .happening {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-areas: "alarm title";
            grid-template-columns: min-content max-content;
            grid-template-rows: 1fr;
            place-content: end;
            column-gap: 1rem;

            .alarm {
              grid-area: alarm;
              font-size: inherit;
              .wicon {
                font-size: 2rem;
              }
            }
            .title {
              grid-area: title;
              align-self: center;
              font-size: 1rem;
            }
          }
        }

        .time {
          display: grid;
          grid-template-areas: "alarm days hours minutes seconds";
          grid-template-columns: min-content min-content min-content min-content min-content;
          column-gap: 1rem;
          place-self: center;
          align-items: center;
          font-size: calc(0.7rem + 0.4vw);

          .alarm {
            grid-area: alarm;
            font-size: inherit;
            .wicon {
              font-size: calc(1rem + 5vw);
            }
          }
          .days, .hours, .minutes, .seconds {
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: min-content;

            .value {
              display: grid;
              justify-content: center;
            }

          }
          .days {
            grid-area: days;
            font-size: inherit;
          }
          .hours {
            grid-area: hours;
            font-size: inherit;
          }

          .minutes {
            grid-area: minutes;
            font-size: inherit;
          }

          .seconds {
            grid-area: seconds;
            font-size: inherit;
          }
        }
      }
    }
  }

  .past {
    grid-area: past;
  }
  .calendar {
    width: 100%;
    height: 100%;
    grid-area: calendar;
    display: grid;
    grid-template-areas:
      " calendarTopMargin calendarTopMargin"
      "calendarLabel icon"
      "calendarBottomMargin calendarBottomMargin";

    grid-template-columns: man-content min-content;
    grid-template-rows: 1rem 1fr 1rem;
    background-color: #13294b;
    color: #f3f3f7;
    place-content: center;
    cursor: pointer;
    .calendarLabel {
      width: 100%;
      place-self: center;
      grid-area: calendarLabel;
      display: grid;
      grid-template-areas: "calendarIcon title";
      column-gap: 1rem;

      .calendarIcon {
        grid-area: calendarIcon;
        place-self: center;
        justify-self: end;
      }
      .title {
        grid-area: title;
        font-size: calc(0.7rem + 1vw);
        place-self: center;
        justify-self: start;
      }
    }

    .icon {
      width: 100%;
      height: 100%;
      grid-area: icon;
      justify-self: right;
      align-self: center;
    }
  }
}
