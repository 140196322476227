.events {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "photo"
    "hosting"
    "upcoming"
    "past";
  grid-template-rows: min-content min-content min-content min-content;
  grid-template-columns: 1fr;
  row-gap: 1em;
  overflow: auto;

  .photo {
    grid-area: photo;
    width: 100%;
    height: 100%;
  }
  .hosting {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 1rem;
    .title {
      font-size: 2rem;
      color: #0057b7;
      padding-left: 1rem;
    }
    .link {
      display: grid;
      align-items: center;
      background-color: #00A3E0;
      font-size: 1.5rem;
      height: 5rem;
      cursor: pointer;
      padding-left: 1rem;

      &:nth-child(even) {
        background-color: #D9D9D6;
        color: #13294B;
      }

      &:hover {
        border-radius: 0.4rem;
        border-bottom: 1px solid #0057b7;
        color: #132948;
        cursor: pointer;
      }
    }
  }
  .upcoming,
  .past {
    
    grid-area: upcoming;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
    row-gap: 1rem;

    .title {
      padding-left: 1rem;
      font-size: 2rem;
      color: #0057b7;
    }
    .event {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-areas:
        "leftMargin . rightMargin"
        "leftMargin description rightMargin"
        "leftMargin date rightMargin"
        "leftMargin . rightMargin";
      grid-template-columns: 1rem 1fr 1rem;
      grid-template-rows: 1rem min-content min-content 1rem;
      grid-auto-rows: max-content;
      // padding: 1rem;
      background-color: rgba(244, 243, 228, 0.8);

      &:nth-child(even) {
        background-color: #D9D9D6;
        color: #13294B;
      }

      &:hover {
        border-radius: 0.4rem;
        border-bottom: 1px solid #0057b7;
        color: #132948;
        cursor: pointer;
      }
      .description {
        grid-area: description;
        font-size: 1.5rem;
        font-family: inherit;
      }
      .date {
        grid-area: date;
      }
    }
  }

  .past {
    grid-area: past;
  }
}
