.customPage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "content";
  grid-template-columns: 1fr;
  grid-template-rows: 5fr;
  overflow: hidden;

  .content {
    width: 100%;
    height: 100%;
    grid-area: content;
    display: grid;
    overflow-x: auto;
    gap: 1rem;
    grid-auto-flow: 1fr;

    .area {
      display: grid;
      width: 100%;
      height: 100%;
      row-gap: 0rem;
      grid-auto-rows: minmax(min-content, max-content);

      .areaRow {
        display: grid;
        height: 100%;
        width: 100%;
        grid-template-areas:
          "leftMargin topMargin rightMargin"
          "leftMargin rowBody rightMargin"
          "leftMargin bottomMargin rightMargin";
        grid-template-rows: var(--topMargin) 1fr var(--bottomMargin);
        grid-template-columns: var(--leftMargin) 1fr var(--rightMargin);

        .leftMargin {
          grid-area: leftMargin;
          width: 100%;
          height: 100%;
          background-color: var(--backgroundColor);
        }
        .rightMargin {
          grid-area: rightMargin;
          width: 100%;
          height: 100%;
          background-color: var(--backgroundColor);
        }
        .topMargin {
          grid-area: topMargin;
          width: 100%;
          height: 100%;
          background-color: var(--backgroundColor);
        }
        .bottomMargin {
          grid-area: bottomMargin;
          width: 100%;
          height: 100%;
          background-color: var(--backgroundColor);
        }
        .rowBody {
          display: grid;
          grid-area: rowBody;
          width: 100%;
          height: 100%;
          grid-template-rows: 1fr;
          grid-auto-flow: column;
          grid-auto-columns: 1fr;
          overflow-x: hidden;
          column-gap: 0rem;
          justify-content: center;
          background-color: var(--backgroundColor);
          color: var(--color);

          .columnContent {
            display: grid;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &.image {
              // max-height: 25rem;
              color: var(--color);
            }

            &.portableText {
              grid-template-areas:
                ". . ."
                ". block ."
                ". . .";
              grid-template-rows: var(--marginTop) min-content var(--marginBottom);
              grid-template-columns: var(--marginLeft) 1fr var(--marginRight);
              align-content: flex-start;
              color: var(--color);
              background-color: var(--backgroundColor);
              .block {
                width: 100%;
                height: 100%;
                display: grid;
                grid-area: block;
                place-content: center;
                align-items: flex-start;

                h1, h2, h3, h4 {
                  margin-top: .5rem;
                  margin-bottom: .5rem;
                  font-family: kallisto, sans-serif;
                }
                h4 {
                  font-family: monserrat, sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }
}
