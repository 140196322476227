.page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-contnent 1fr;
  row-gap: 1rem;
  overflow: hidden;

  .groups {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    row-gap: 1rem;
    overflow: auto;
    .group {
        .title {
            color: #13294B;
            margin-bottom: .5rem;
            font-size: 1.3rem;
        }
      .links {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        row-gap: 1rem;
        
        .link {
          border-radius: 0.5rem;
          font-family: kallisto, sans-serif;
          font-size: inherit;
          font-weight: bold;
          padding: 1rem;
          text-align: center;
          outline: none;
          text-decoration: none;
          background-color: #d9d9d6;

          &:link {
            color: black;
          }

          &:visited {
            color: #222222;
          }

          &:focus {
            border-bottom: 2px solid;
          }
          &:hover {
            border-bottom: 2px solid;
            opacity: 0.8;
          }
          &:active {
            color: white;
          }
        }
      }
    }
  }
}
