.members {
    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    overflow-x: hidden;
    row-gap: 2rem;

    .country {
        display: grid;
        width: 100%;
        // overflow-x: scroll;
        grid-template-areas: "header" "website";
        grid-template-columns: max-content max-content;
        grid-template-rows: min-content min-content;
        column-gap: 1rem;
        
        .accordion {
            grid-area: header;
            width: 90vw;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }

        .website {
            font-family: monserrat, sans-serif;
            font-weight: normal;
            grid-area: website;
        }
    }

}
.header {
    display: grid;
    width: 80vw;
    grid-template-areas: "flag name code fedName";
    grid-template-columns: minmax(0, .5fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 4fr);
    grid-template-rows: 1fr;
    column-gap: 1rem;
    font-size: .75rem;
    align-items: center;;
    .flag {
        grid-area: flag;
    }
    .countryName {
        grid-area: name;
    }
    .countryCode {
        grid-area: code;
    }
    .fedName {
        grid-area: fedName;
    }
}