.main {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas: "app";
  grid-template-columns: 100%;
  justify-content: space-around;
  .app {
    grid-area: app;
    font-family: kallisto, sans-serif;
    font-weight: 700;
    font-size: medium;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-areas: "topPanel" 
                          "page" 
                          "bottomMargin";
    grid-template-rows: 4rem 1fr 1rem;
    grid-template-columns: 1fr;
    row-gap: 0rem;
    overflow: hidden;
    justify-content: center;

    .topPanel {
      display: grid;
      width: 100%;
      height: 100%;
      grid-area: topPanel;
      overflow: hidden;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
    .page {
      grid-area: page;
      height: 100%;
      width: 100%;
      min-width: 200px;
      max-width: 1440px;
      justify-self: center;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      overflow: hidden;
    }
    .bottomMargin {
      width: 100%;
      height: 100%;
      min-width: 200px;
      max-width: 1440px;
      grid-area: bottomMargin;
    }
  }
}
