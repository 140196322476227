.welcome {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;

  .panam {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .image {
      width: 100%;
      height: 100%;
      fill: black;
      place-self: center;
    }
  }
  .title {
    width: 100%;
    height: 100%;
    position: absolute;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    top: 0;
    left: 0;
    font-size: 2.5rem;
    color: white;
    text-align: center;
    place-items: center;
    background-color: transparent;
    opacity: 1;
  }
}
