.userFile {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 5rem;

    .name {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        place-content: center;
        font-size: 1.3rem;
        border-radius: .5rem;
        cursor: pointer;

        .text {
            place-self: center;
            padding:.5rem;
        }
    }
}