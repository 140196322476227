.chats {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  .video {
    display: grid;
    grid-template-areas: "description link";
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr;
    .description {
      grid-area: description;
      place-self: center;
      display: grid;
      place-content: center;
      padding: 1rem;
    }
    .link {
      grid-area: link;
      place-self: center;
      justify-self: end;
    }

  }
  .video:nth-child(even) {
    background-color: #D9D9D6;
  }
 }
