.easyPage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  overflow: auto;

 
  .column {
    display: grid;
    grid-column: var(--gridColumn, 1 / -1);
    grid-row: var(--gridRow);
    width: 100%;
    height: 100%;
    
    grid-template-areas: "marginTop marginTop marginTop"
                        "marginLeft body marginRight"
                        "marginBottom marginBottom marginBottom";
    grid-template-columns: var(--marginLeft, 0) 1fr var(--marginRight, 0);
    grid-template-rows: var(--marginTop, 0) 1fr var(--marginBottom, 0);
    overflow: hidden;
    place-content: center;
    background-color: var(--backgroundColor);
    color: var(--color);

    .body {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-area: body;
      place-content: center;
    }
    .marginLeft {
      height: 100%;
      width: 100%;
      grid-area: marginLeft;
    }
    .marginRight {
      height: 100%;
      width: 100%;
      grid-area: marginRight;
    }
    .marginTop{
      height: 100%;
      width: 100%;
      grid-area: marginTop;
    }
    .marginBottom {
      height: 100%;
      width: 100%;
      grid-area: marginBottom;
    }
  }

  .image {
    width: var(--width, 100%);
    height: var(--height, 100%);
    display: grid;
    place-content: center center;
    // background-image: var(--backgroundImage, unset);
    background-image: var(--background-image, unset);
    background-repeat:repeat-x;
    background-size: contain;
    background-position: right;
    
    cursor: var(--cursor);

    .img, .notImage {
      display: grid;
      width: var(--width, unset);
      height: var(--height, unset);
      cursor: var(--cursor, unset);
      align-self: center;
    }

  }
  .separator {
    align-self: center;
    width: var(--width, unset);
    height: var(--height, unset);
    background-color: var(--backgroundColor);
    color: var(--color);
  }
  .portableText {
    display: grid;
    place-content: center center;
    
    h6 {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    p {
      font-family: monserrat;
      font-weight: 400;
    }
  }
  .youtube {
    width: 100%;
    display: grid;
    place-content: center;
    overflow: hidden;

    .video {
      height: 200px;
      width: 400px;
    }
  }
}
