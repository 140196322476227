.menuBar {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: #fafafa;

    .buttons {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-columns: min-content;
        grid-auto-flow: column;
        overflow: auto;
        background-color: #F3F3E7;

        .button {
            cursor: pointer;
            font-family: inherit;
            font-weight: 800;
            font-size: 1rem;
            background-color: #F3F3E7;
            color: #13294B;
            border: none;
            white-space: nowrap;

            &.open {
                color: #00A3E0;
            }
          }
    }
}