.event {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(10rem, 30%);
  grid-auto-rows: min-content;
  row-gap: 1rem;

  overflow: hidden;
  overflow-y: auto;

  .photo {
    grid-area: photo;
    width: 100%;
    height: 100%;
  }
  
  .eventDetails,
  .venueLabel,
  .scheduleLabel,
  .resultsLabel {
    font-size: 1.3rem;
  }

  .host {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 0.5rem;
  }

  .host,
  .dateAndLocation,
  .venue,
  .schedule,
  .results {
    font-family: kallisto, sans-serif;
    font-weight: bold;
    border-radius: 0.5rem;
    outline: none;
    border: none;    
  }

  .embeddedSchedule {
    width: 100%;
    height: auto;
    min-height: 28rem;
  }

  .results {
    background-color: #eeeeee;
  }

  .hostWebsite,
  .hostSummons,
  .hostSupporters,
  .schedule,
  .results,
  .bioSecurity {
    border-radius: 0.5rem;
    font-family: kallisto, sans-serif;
    font-size: inherit;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    outline: none;
    text-decoration: none;

    &:link {
      color: black;
    }

    &:visited {
      color: #222222;
    }

    &:focus {
      border-bottom: 2px solid;
    }
    &:hover {
      border-bottom: 2px solid;
      opacity: 0.8;
    }
    &:active {
      color: white;
    }
  }

  .hostWebsite {
    background-color: #d9d9d6;
  }
  .hostSummons {
    background-color: #00a3e0;
  }
  .hostSupporters {
    background-color: #0057b7;
    color: rgb(244, 243, 228);
    &:visited {
      color: rgb(244, 243, 228);
    }
  }
  .schedule {
    background-color: rgb(244, 243, 228);
  }
}
