.bulletin {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: "link";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    overflow: auto;

    .link {
        grid-area: link;
        width: 100%;
        height: 100%;
    }
}
