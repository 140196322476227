.about {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(12rem, 45%);
    grid-auto-rows: min-content;
    row-gap: .5rem;
    overflow: hidden;
    overflow-y: auto;

    .header {
        display: grid;
        position :relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        .swimmer {
          width: 100%;
          place-self: center;
          //height: 100%;
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
    
          .image {
            width: 100%;
            //height: 100%;
            fill: black;
            place-self: center;
          }
          
        }
        .title {
          width: 100%;
          height: 100%;
          position: absolute;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr;
          top: 0;
          left: 0;
          font-size: 2rem;
          color: white;
          text-align: center;
          align-items: center;
          background-color: transparent;
          opacity: 1;
        }
      }
    




    .whoIsDescription, .inASentenceLabel, .inASentence, .mission , .vision 
    , .valuesInclusive, .valuesCollaborative, .valuesGroundbreaking {
        font-family: monserrat, sans-serif;
        font-weight: normal;
    }

    .values {
        display: grid;
        grid-template-areas: ". inclusive"
        ". collaborative"
        ". groundbreaking";
        grid-template-columns: 1rem max-content;
        grid-template-rows: 1rem max-content;

        .valuesInclusive {
            grid-area: inclusive;
        }
        .valuesCollaborative {
            grid-area: collaborative;
        }
        .valuesGroundbreaking {
            grid-area: groundbreaking;
        }
        
    }
}