.social {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: "facebook instagram";
  grid-template-rows: 1fr;
  grid-template-columns: 29px 29px;
  align-items: center;
  column-gap: .5rem;
  justify-content: end;

  .facebook {
    grid-area: facebook;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .logo {
      width: 100%;
      height: 100%;
    }
  }

  .instagram {
    grid-area: instagram;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .logo {
      width: 100%;
      height: 100%;
    }
  }
}
