.webinars {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  .video {
    display: grid;
    grid-template-areas: "disciplines description link";
    grid-template-columns:1fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    .disciplines {
      width: 100%;
      height: 100%;
      grid-area: disciplines;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;

      .image {
        background-size: contain;
        background-repeat: no-repeat;
        margin: 1rem;
        display: grid;
        place-content: center;

      }
    }
    .description {
      grid-area: description;
      place-self: center;
      display: grid;
      place-content: center;
      padding: 1rem;
    }
    .link {
      grid-area: link;
      place-self: center;
      justify-self: end;
    }

  }
  .video:nth-child(even) {
    background-color: #D9D9D6;
  }
 }
