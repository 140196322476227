.discipline {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 5rem 1fr;
    grid-template-rows: 5rem;
    column-gap: 1rem;
    overflow: hidden;
    place-content: center;
   

    &.logo {
        
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-areas: "photo"
                            "label";
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content;
        row-gap: .3rem;
        align-items: center;
        cursor: pointer;

        .photo {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 100%;
            max-height: 115px;
            // height: 100%;
            grid-area: photo;
            
        }
        .label {
            grid-area: label;
            display: grid;
            width: 100%;
            // height: 100%;
            overflow: hidden;
            

            .text {
                font-family: kallisto, sans-serif;
                display: grid;
                white-space: wrap;
                text-align: center;
                font-size:25%;
                font-weight: 800;
                font-stretch:extra-condensed;
            }
            

        }

    }

    &.portrait {
        grid-template-columns: 1fr ;
        grid-template-rows: 6rem 3rem;
        
        .image {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            background-color: #F3F3E7;
            border-radius: .5rem;
            // place-self: center;
    
            .img {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                place-self: center;
                width: 90%;
                height: 90%;
            }
        }

        .name {
            width: unset;
            height: 100%;
            display: grid;
            font-size: calc(.7rem + 1vw);
            overflow-wrap: break-word;
           
        
            
           .text {
            //width: 80%;
            place-self: center;


           }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        background-color: #F3F3E7;
        border-radius: .5rem;
        // place-self: center;

        .img {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            place-self: center;
            width: 45%;
            height: 80%;
        }
    }
    .name {
        width: 100%;
        height: 100%;
        display: grid;
        // grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: 1.5rem;
        border-radius: .5rem;
        cursor: pointer;
    }

}
@keyframes createPhoto {
    from {
      transform: scale(.2) rotate(90deg);
    }
    to {
      transform: scale(1) rotate(0deg); 
    }
  }