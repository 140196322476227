.partners {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: "firstLevel" 
                        "secondLevel" 
                        "thirdLevel";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 2rem 2rem;
    place-content: center;
    column-gap: 1rem;
    row-gap: 1rem;
   

    .firstLevel {
        width: 100%;
        grid-area: firstLevel;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-areas: "fina panamSports fluidra";
        grid-template-columns: repeat(3, minmax(min-content, 4rem));
        place-content: center;
    }
    .secondLevel {
        width: 100%;
        grid-area: secondLevel;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-areas: "CONSANAT CCCAN USAS ACA";
        grid-template-columns: repeat(4, minmax(min-content, 2rem));
        place-content: center;
        column-gap: .5rem;
    }
    .thirdLevel {
        width: 100%;
        grid-area: thirdLevel;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-areas: "hastyAwards";
        grid-template-columns: 3rem;
        place-content: center;
    }
    
    .fina {
        grid-area: fina;
        height: 100%;
        place-self: unset;
        overflow: hidden;
        cursor: pointer;
    }

    .panamSports {
        grid-area: panamSports;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: 100%;
        width: 100%;
        place-self: unset;
        overflow: hidden;
        cursor: pointer;
    }

    .fluidra {
        grid-area: fluidra;
        height: 100%;
        width: 100%;
        place-self: unset;
        overflow: hidden;
        cursor: pointer;
    }

    .CONSANAT {
        grid-area: CONSANAT;
        height: 100%;
        place-self: unset;
        overflow: hidden;
    }

    .CCCAN {
        grid-area: CCCAN;
        height: 100%;
        place-self: unset;
        overflow: hidden;
    }

    .USAS {
        grid-area: USAS;
        height: 100%;
        place-self: unset;
        overflow: hidden;
    }

    .ACA {
        grid-area: ACA;
        height: 100%;
        place-self: unset;
        overflow: hidden;
    }

    .hastyAwards {
        grid-area: hastyAwards;
        height: 100%;
        width: 100%;
        place-self: unset;
        overflow: hidden;
        cursor: pointer;
    }
}